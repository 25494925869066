
const MobileMenu = ({ show, close }) => {

  return (
    <div className={`mobile-slide-panel ${show ? "panel-on" : ""}`}>
      <div className="panel-overlay" onClick={() => close()}></div>
      <div className="panel-inner">
        <div className="mobile-logo">
          <a href="/">
            <img src="images/Dimencore-logo.png" alt="Dimencore" />
          </a>
        </div>
        <nav className="mobile-menu">
          <ul>
            <li>
              <a href="/">
                Home
              </a>
            </li>
            <li>
              <a href="/services">
                Services
              </a>
            </li>
            <li>
              <a href="/contact">Contact</a>
            </li>
          </ul>
        </nav>
        <a href="#" className="panel-close" onClick={() => close()}>
          <i className="fal fa-times"></i>
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
