const Footer = () => {
    return (
        <footer className="template-footer">
            <div className="container">
                <div className="footer-widgets p-t-80 p-b-30">
                    <div className="row">
                        {/* Single Footer Widget */}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget text-block-widget">
                                <h5 className="widget-title">About Dimencore</h5>
                                <p>
                                    At Dimencore, we specialize in providing tailored IT solutions designed to
                                    help businesses thrive in today’s fast-paced digital landscape.
                                </p>
                                <ul className="download-links">
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-apple" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-google-play" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-windows" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Single Footer Widget */}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="d-lg-flex justify-content-center">
                                <div className="widget nav-widget">
                                    <h5 className="widget-title">Company</h5>
                                    <ul>
                                        <li>
                                            <a href="/about">About Dimencore</a>
                                        </li>
                                        <li>
                                            <a href="/contact">Contact &amp; Support</a>
                                        </li>
                                        <li>
                                            <a href="/services">Our Services</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* Single Footer Widget */}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget social-widget pl-xl-5">
                                <h5 className="widget-title">Follow On</h5>
                                <ul className="social-links">
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-facebook-f" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-twitter" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-youtube" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-behance" />
                                        </a>
                                    </li>
                                    <li>
                                        <a href="#">
                                            <i className="fab fa-linkedin" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {/* Single Footer Widget */}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="widget contact-widget pl-xl-5">
                                <h5 className="widget-title">Contact Us</h5>
                                <ul>
                                    <li>
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a href="#">
                                            <i className="fal fa-map-marker-alt" />
                                            7/1120, AVS Naveen Richland, Neruperichal, Tirupur, Tamil Nadu, India
                                        </a>
                                    </li>
                                    <li>
                                        <a href="mailto:support@gmail.com">
                                            <i className="fal fa-envelope-open-text" />
                                            md@dimencore.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+919944663399">
                                            <i className="fal fa-phone" />
                                            +91 9944663399
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright border-top-secondary">
                <div className="container">
                    <p className="copyright-text text-center">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        © 2024 <a href="#">Dimencore</a>. All Rights Reserved
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;