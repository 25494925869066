
const Nav = ({ activeMenu, setActiveMenu }) => {
  return (
    <nav className="nav-menu d-none d-xl-block">
      <ul>
        <li className={activeMenu === 'home' ? 'active' : ''} onMouseEnter={() => setActiveMenu('home')}>
          <a href="/">
            Home
          </a>
        </li>
        <li className={activeMenu === 'services' ? 'active' : ''} onMouseEnter={() => setActiveMenu('services')}>
          <a href="/services">
            Services
          </a>
        </li>
        <li className={activeMenu === 'contact' ? 'active' : ''} onMouseEnter={() => setActiveMenu('contact')}>
          <a href="/contact">
            Contact
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Nav;
