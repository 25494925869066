import React, { Fragment, useState } from "react";

// components
import Header from "./header";

// Pages
import Banner from "./banner";
import ScrollTop from "./ScrollTop";
import Footer from "./footer";

const Layout = (props) => {
    const [activeMenu, setActiveMenu] = useState('home');

    const { pageTitle, children, noHeader, noFooter } = props;

    return <Fragment>
        {!noHeader && <Header activeMenu={activeMenu} setActiveMenu={setActiveMenu} />}
        {pageTitle && <Banner pageName={pageTitle} />}
        {children}
        <ScrollTop />
        {!noFooter && <Footer />}
    </Fragment>;
};

export default Layout;
