import React from "react";
import Layout from "../layouts";
import Slider from "react-slick";
import { serviceSliderActive } from "../sliderProps";

const Home = () => {
    return (
        <Layout>
            <section className="hero-area-v7">
                <div className="container-fluid">
                    <div className="hero-content">
                        <h2 className="hero-title wow fadeInLeft" data-wow-delay="0.2s">
                            Empowering Your Business with
                        </h2>
                        <p className="wow fadeInUp" data-wow-delay="0.3s">
                            At Dimencore, we specialize in developing innovative software solutions that drive growth and efficiency for businesses of all sizes.
                        </p>
                    </div>
                    <div
                        className="hero-img d-none d-lg-block wow fadeInUp"
                        data-wow-delay="0.2s"
                    >
                        {/* <img src="images/banner/vehicle-route.jpg" alt="Image" /> */}
                    </div>
                </div>
            </section>
            {/*====== Section Service End ======*/}
            {/*====== Start Design & Layout Section ======*/}
            <section className="sof-design-layout p-t-130 p-b-130 bg-heading-color">
                <div className="container">
                    <div className="row align-items-center justify-content-center no-gutters">
                        <div className="col-lg-7">
                            <div className="software-preview m-b-md-80 animate-float-bob-y">
                                <img
                                    src="images/banner/software-design.jpg"
                                    style={{ borderRadius: '15px' }}
                                    alt="Image"
                                />
                            </div>
                        </div>
                        <div className="col-lg-5 col-md-10">
                            <div className="software-text-block p-l-50 p-l-lg-30 p-l-md-0">
                                <div className="common-heading m-b-40">
                                    <span className="tagline color-primary-5">
                                        Design &amp; Layouts
                                    </span>
                                    <h2 className="title" style={{ color: '#fff' }}>Keep Things Simple With Ability</h2>
                                </div>
                                <p>
                                    Our design and layout services focus on simplicity and functionality,
                                    ensuring that your projects are both visually appealing and easy to use.
                                </p>
                                <div
                                    className="icon-bordered-box icon-left m-t-30 wow fadeInLeft"
                                    data-wow-delay="0.3s"
                                >
                                    <div className="icon">
                                        <i className="far fa-gem" />
                                    </div>
                                    <div className="box-content">
                                        <h4 className="title" style={{ color: '#fff' }}>User Attractive</h4>
                                        <p>
                                            Create engaging and dynamic user experiences,
                                            making your projects not only visually appealing but also interactive and memorable.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*====== Services Section Start ======*/}
            <section className="service-section bg-soft-grey-color p-t-130 p-b-130">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6 col-md-10">
                            <div className="common-heading text-center m-b-50">
                                <h2 className="title m-b-20">Our Services</h2>
                                <p>
                                    Our services are designed to meet your specific needs and help you achieve your business goals.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row image-boxes-v2">
                        <div
                            className="col-lg-3 col-sm-6 wow fadeInUp"
                            data-wow-delay="0.2s"
                        >
                            <div className="image-box m-t-30">
                                <div className="box-image hover-overly-zoom">
                                    <img
                                        src="images/content/webDevelopment.jpg"
                                        alt="Image"
                                    />
                                </div>
                                <div className="box-content">
                                    <h5 className="title">
                                        <a href="/service-details">Web Development</a>
                                    </h5>
                                    <p>We provide top-notch web development services to build responsive and scalable websites.</p>
                                    <a href="/contact">
                                        <a className="box-link">
                                            Get Quote <i className="fas fa-arrow-right" />
                                        </a>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 wow fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="image-box m-t-30">
                                <div className="box-image hover-overly-zoom">
                                    <img
                                        src="images/content/androidDev.avif"
                                        alt="Image"
                                    />
                                </div>
                                <div className="box-content">
                                    <h5 className="title">
                                        <a href="/contact">Android Development</a>
                                    </h5>
                                    <p>Our Android development services ensure high-performance and feature-rich mobile applications.</p>
                                    <a href="/contact">
                                        <a className="box-link">
                                            Get Quote <i className="fas fa-arrow-right" />
                                        </a>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 wow fadeInUp"
                            data-wow-delay="0.4s"
                        >
                            <div className="image-box m-t-30">
                                <div className="box-image hover-overly-zoom">
                                    <img
                                        src="images/content/ios.png"
                                        alt="Image"
                                    />
                                </div>
                                <div className="box-content">
                                    <h5 className="title">
                                        <a href="/contact">iOS Development</a>
                                    </h5>
                                    <p>We specialize in creating seamless and intuitive iOS applications for a superior user experience.</p>
                                    <a href="/contact">
                                        <a className="box-link">
                                            Get Quote <i className="fas fa-arrow-right" />
                                        </a>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-sm-6 wow fadeInUp"
                            data-wow-delay="0.5s"
                        >
                            <div className="image-box m-t-30">
                                <div className="box-image hover-overly-zoom">
                                    <img
                                        src="images/content/uiux.jpg"
                                        alt="Image"
                                    />
                                </div>
                                <div className="box-content">
                                    <h5 className="title">
                                        <a href="/contact">UI/UX Development</a>
                                    </h5>
                                    <p>Our UI/UX services focus on creating user-friendly and aesthetically pleasing interfaces.</p>
                                    <a href="/contact">
                                        <a className="box-link">
                                            Get Quote <i className="fas fa-arrow-right" />
                                        </a>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*====== End Hero Area ======*/}
            {/*====== Section Service Start ======*/}
            <section className="service-section p-t-130 p-b-130 bg-secondary-color-5">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-10">
                            <div className="common-heading text-center heading-white m-b-30">
                                <h2 className="title">
                                    {`Optimize, Grow, and Transform with Our Customizable IT Services`}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gutters justify-content-lg-between justify-content-md-start justify-content-center icon-boxes-v3">
                        <div
                            className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp"
                            data-wow-delay="0.2s"
                        >
                            <div className="iconic-box box-version-white color-v1 m-t-50">
                                <div className="icon">
                                    <i className="far fa-code" />
                                </div>
                                <h4 className="title">
                                    <a href="#">Customized Software Development</a>
                                </h4>
                                <p>
                                    We specialize in the development, design, programming, and maintenance of software applications, systems, and solutions across diverse industries. Our technology services are customized and scalable to meet the unique needs of your business.
                                </p>
                                <a href="#" className="box-btn">
                                    Learn More <i className="far fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp"
                            data-wow-delay="0.3s"
                        >
                            <div className="iconic-box box-version-white color-v2 m-t-50">
                                <div className="icon">
                                    <i className="fas fa-lightbulb" />
                                </div>
                                <h4 className="title">
                                    <a href="#">IT Consultancy & Strategy</a>
                                </h4>
                                <p>
                                    We offer comprehensive IT consultancy, including IT strategy development,
                                    systems analysis, project management, and technology implementation.
                                    Our goal is to optimize your business processes and enhance operational efficiency.
                                </p>
                                <a href="#" className="box-btn">
                                    Learn More <i className="far fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                        <div
                            className="col-lg-3 col-md-6 col-sm-10 wow fadeInUp"
                            data-wow-delay="0.4s"
                        >
                            <div className="iconic-box box-version-white color-v3 m-t-50">
                                <div className="icon">
                                    <i className="fas fa-database" />
                                </div>
                                <h4 className="title">
                                    <a href="#">Data Management & Hosting Services</a>
                                </h4>
                                <p>
                                    Our range of IT services includes data processing, hosting, and related activities to
                                    help businesses manage and utilize data effectively for improved decision-making.
                                </p>
                                <a href="#" className="box-btn">
                                    Learn More <i className="far fa-arrow-right" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="service-section p-t-130 p-b-130 bg-soft-grey-color">
                <div className="container-fluid fluid-gap-70">
                    {/* <!-- Common Heading --> */}
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="common-heading tagline-boxed-two title-line m-b-80 text-center">
                                {/* <span className="tagline">Popular Services</span> */}
                                <h2 className="title">
                                    Technology We Work With
                                </h2>
                            </div>
                        </div>
                    </div>
                    {/* <!-- Image boxes --> */}
                    <Slider
                        {...serviceSliderActive}
                        className="row image-boxes-v1 image-thumbnail-boxed"
                        id="serviceSliderActive"
                    >
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/androidTech.png"
                                        style={{ maxWidth: 'unset', width: '200px' }}
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">Android</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/iosTech.webp"
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">iOS</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/jsTech.png"
                                        style={{ maxWidth: 'unset', width: '200px' }}
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">JavaScript</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/reactTech.jpg"
                                        style={{ maxWidth: 'unset', width: '250px' }}
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">React JS</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/flutterTech.png"
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">Flutter</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="images/content/js.webp"
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">Java</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>
                        <div className="col">
                            <div className="image-box">
                                <div className="thumb">
                                    <img
                                        src="assets/img/services/service-thumbnail-9.png"
                                        alt="Image"
                                    />
                                </div>
                                <h4 className="title">Node JS</h4>
                                <a href="/contact">
                                    <a className="box-link">
                                        <i className="fas fa-arrow-right"></i>
                                    </a>
                                </a>
                            </div>
                        </div>

                    </Slider>
                </div>
            </section>
            {/* <!--====== Service Section Start ======--> */}
            <section className="service-section p-t-130 p-b-130">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-7">
                            <div className="common-heading title-line-bottom text-center m-b-80">
                                <h2 className="title">
                                    Made Amazing Mobile Application With Our Solutions
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-end justify-content-lg-between justify-content-center">
                        <div className="col-lg-5 col-md-8">
                            <div className="service-img text-center text-lg-center">
                                <img
                                    src="images/banner/mobile-app-testimonial.png"
                                    alt="Image"
                                    className="animate-float-bob-y"
                                />
                            </div>
                        </div>

                        <div className="col-xl-6 col-lg-7">
                            <div className="row no-gutters iconic-boxes-v1 icon-boxes-square-bordered">
                                <div className="col-sm-6 col-12">
                                    <div className="iconic-box">
                                        <div className="icon icon-gradient-3">
                                            <i className="far fa-user-chart"></i>
                                        </div>
                                        <h4 className="title">Team Management</h4>
                                        <p>
                                            Manage your team efficiently with our advanced tools and features.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="iconic-box">
                                        <div className="icon icon-gradient-4">
                                            <i className="far fa-compass"></i>
                                        </div>
                                        <h4 className="title">Time Tracking</h4>
                                        <p>
                                            Track your team's time effectively with our comprehensive time tracking tools.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="iconic-box">
                                        <div className="icon icon-gradient-5">
                                            <i className="far fa-squirrel"></i>
                                        </div>
                                        <h4 className="title">Payment System</h4>
                                        <p>
                                            Manage your payments and financial transactions seamlessly with our payment system.
                                        </p>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-12">
                                    <div className="iconic-box">
                                        <div className="icon icon-gradient-6">
                                            <i className="far fa-fingerprint"></i>
                                        </div>
                                        <h4 className="title">High Security</h4>
                                        <p>
                                            Ensure the highest level of security for your data and systems with our robust security measures.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/*====== End Call To Action ======*/}
            {/*====== Start Scroll To Top ======*/}
            <a href="#" className="back-to-top" id="scroll-top">
                <i className="far fa-angle-up" />
            </a>
        </Layout>
    );
};

export default Home;