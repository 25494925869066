// import Slider from "react-slick";
import Layouts from "../../layouts/layouts";
import AccordionV1 from "../../components/AccordionV1";

const Services = () => {
  return (
    <Layouts pageTitle="Our Services">
      <section className="service-details-area p-t-130 p-b-130">
        <div className="container">
          <div className="service-details-content">
            <h2 className="service-title">Web, Android, IOS Development</h2>

            <p className="m-b-30">
              Our Web, Android, and IOS development services are tailored to meet the unique needs of your business. 
              We ensure high-quality, scalable, and efficient solutions that drive growth and success. 
              Our team of experts uses the latest technologies and best practices to deliver exceptional results, 
              ensuring your digital presence is robust and competitive. 
              We offer comprehensive development services, including custom web applications, mobile app development, and responsive design. 
              Our goal is to provide seamless user experiences across all platforms, enhancing customer engagement and satisfaction. 
              Trust us to transform your ideas into reality with our innovative and reliable development services.
            </p>

            <div className="row">
              <div className="col-md-6">
                <div className="m-b-30">
                  <img
                    src="images/services/services1.jpg"
                    alt="Service One"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="m-b-30">
                  <img
                    src="images/services/services2.avif"
                    alt="Service Two"
                  />
                </div>
              </div>
            </div>

            <h3 className="service-subtitle">
              Results For Web, Android, IOS Development In Business
            </h3>

            <p>
              Our Web, Android, and IOS development services have a significant impact on businesses by enhancing their digital presence and operational efficiency. 
              We provide comprehensive solutions that cater to the specific needs of each client, ensuring that their digital platforms are robust, scalable, and user-friendly. 
              Our team of experts leverages the latest technologies and industry best practices to deliver high-quality applications that drive business growth and success. 
              From custom web applications to mobile app development and responsive design, we ensure seamless user experiences across all devices. 
              Our services not only improve customer engagement but also boost overall business performance by streamlining processes and increasing productivity. 
              Trust us to deliver innovative and reliable development solutions that transform your business ideas into reality.
            </p>

            <div className="row iconic-boxes-v2">
              <div
                className="col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.2s"
              >
                <div className="iconic-box m-t-50">
                  <div className="icon">
                    <img
                      src="images/services/custom.jpg"
                      width={100}
                      alt="Business"
                    />
                  </div>
                  <h5 className="title">
                    <a href="#">Custom Web Applications</a>
                  </h5>
                  <p>
                    We develop custom web applications tailored to your business needs, ensuring high performance and scalability.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <div className="iconic-box m-t-50">
                  <div className="icon">
                    <img
                      src="images/services/mobile.png"
                      width={140}
                      alt="Consulting"
                    />
                  </div>
                  <h5 className="title">
                    <a href="#">Mobile App Development</a>
                  </h5>
                  <p>
                    Our mobile app development services cover both Android and IOS platforms, providing seamless user experiences.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.4s"
              >
                <div className="iconic-box m-t-50">
                  <div className="icon">
                    <img src="images/services/responsive.jpg" width={110} alt="Finance" />
                  </div>
                  <h5 className="title">
                    <a href="#">Responsive Design</a>
                  </h5>
                  <p>
                    We ensure that all our web and mobile applications are fully responsive, providing optimal viewing experiences across all devices.
                  </p>
                </div>
              </div>
              <div
                className="col-lg-3 col-sm-6 wow fadeInUp"
                data-wow-delay="0.5s"
              >
                <div className="iconic-box m-t-50">
                  <div className="icon">
                    <img src="images/services/content.png" width={140} alt="Quality" />
                  </div>
                  <h5 className="title">
                    <a href="#">Content Topics</a>
                  </h5>
                  <p>
                    Our content topics cover a wide range of subjects, ensuring comprehensive and engaging material for your audience.
                  </p>
                </div>
              </div>
            </div>

            <div className="service-faq p-t-70 p-b-70">
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-6 col-md-10 order-lg-last">
                  <div className="faq-content">
                    <h3 className="service-subtitle">
                      Frequently Asked Questions
                    </h3>

                    <div className="Dimencore-accordion-v1 accordion-bordered">
                      <AccordionV1 />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-9 order-lg-first">
                  <div className="faq-image text-lg-left m-t-md-60">
                    <img
                      src="images/banner/FAQ.jpg"
                      alt="faq image"
                    />
                  </div>
                </div>
              </div>
            </div>

            <p>
              {`On the other hand, we denounce with righteous indignation and dislike men who are beguiled demoralized by the charms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound to ensue and equal blame "On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized by the charms of pleasure`}
            </p>
          </div>
        </div>
      </section>
      {/* <Subscribe /> */}
    </Layouts>
  );
};

export default Services;
