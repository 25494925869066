import { useState } from "react";
import MobileMenu from "./MobileMenu";
import Nav from "./Nav";

const Header = ({ activeMenu, setActiveMenu }) => {
    const [mobileMenuTrigger, setMobileMenuTrigger] = useState(false);
    const [toggle, setToggle] = useState(false);
    //   const context = useContext(SomeContext);

    //   if (context === null) {
    //     return <div>Error: Context is null</div>;
    //   }

    return (
        <header className="template-header navbar-left absolute-header nav-primary-5 submenu-primary-5 sticky-header">
            <div className="container-fluid container-1430">
                <div className="header-inner">
                    <div className="header-left">
                        <div className="brand-logo">
                            <a href="/">
                                <img src="images/Dimencore-logo.png" width={150} height={40} alt="logo" />
                            </a>
                        </div>
                        <Nav activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
                    </div>
                    <div className="header-right">
                        <ul className="header-extra">
                            <li className="d-none d-xl-block">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href="#"
                                    className="off-canvas-btn"
                                    onClick={() => setToggle(true)}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                            </li>
                            <li className="d-xl-none">
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                    href="#"
                                    className="navbar-toggler"
                                    onClick={() => setMobileMenuTrigger(true)}
                                >
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Start Mobile Slide Menu */}
            <MobileMenu
                show={mobileMenuTrigger}
                close={() => setMobileMenuTrigger(false)}
            />
            {/* End Mobile Slide Menu */}
            <div className={`off-canvas-wrapper ${toggle ? "canvas-on" : ""}`}>
                <div className="canvas-overlay" onClick={() => setToggle(false)} />
                <div className="canvas-inner">
                    <div className="canvas-logo">
                        <img src="images/Dimencore-logo.png" width={150} height={40} alt="Canvas Logo" />
                    </div>
                    <div className="about-us">
                        <h5 className="canvas-widget-title">About Us</h5>
                        <p>
                            At Dimencore, we specialize in providing tailored IT solutions designed to 
                            help businesses thrive in today’s fast-paced digital landscape. 
                            Our focus is on enhancing productivity and driving operational success through customized technology services. 
                            Whether you're looking to optimize business processes, develop cutting-edge software, or integrate complex systems, 
                            we’re here to deliver comprehensive solutions that align with your unique needs.
                        </p>
                    </div>
                    <div className="contact-us">
                        <h5 className="canvas-widget-title">Contact Us</h5>
                        <ul>
                            <li>
                                <i className="far fa-map-marker-alt" />
                                7/1120, AVS Naveen Richland, Neruperichal, Tirupur, Tamil Nadu, India
                            </li>
                            <li>
                                <i className="far fa-envelope-open" />
                                <a href="mailto:support@dimencore.com">support@dimencore.com</a>
                                <a href="www.dimencore.com" target="_blank">
                                    www.dimencore.com
                                </a>
                            </li>
                            <li>
                                <i className="far fa-phone" />
                                <span>
                                    <a href="tel:+917845740044">+91 7845740044</a>
                                </span>
                                <span>
                                    <a href="tel:+91 9944663399">+91 9944663399</a>
                                </span>
                            </li>
                            <li>
                                <i className="fab fa-telegram-plane" />
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a href="mailto:md@dimencore.com" target="_blank" rel="noreferrer">md@dimencore.com</a>
                            </li>
                        </ul>
                    </div>
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a href="#" className="canvas-close" onClick={() => setToggle(false)}>
                        <i className="fal fa-times" />
                    </a>
                </div>
            </div>
        </header>
    );
};

export default Header;
